export const parseCookies = () => {
    let cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(cookieString => {
            let cookie = cookieString.trim().match(/(\w+)=(.*)/);

            if (cookie !== undefined) {
                cookies[cookie[1]] = decodeURIComponent(cookie[2]);
            }
        });
    }
    return cookies;
};