import {memo} from "react";
import "./index.scss";


const CustomSwitch = ({firstButtonName, secondButtonName, checked, setChecked}) => (
    <div className="custom-switch">
        <button
            className="custom-switch__button"
            onClick={() => setChecked(false)}
        >
            {firstButtonName}
        </button>
        <button
            className="custom-switch__button"
            onClick={() => setChecked(true)}
        >
            {secondButtonName}
        </button>
        <span className={`custom-switch__cover${checked ? ' _active' : ''}`}>
            {checked ? secondButtonName : firstButtonName}
        </span>
    </div>
);

export default memo(CustomSwitch);