import {memo, Suspense} from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import Loading from "./components/Loading";
import Layout from "./components/Layout";
import {routes} from "./pages/Routes";
import {useAuth} from "./hooks/auth.hook";
import {AuthContext} from "./context/AuthContext";


const App = () => {
    const {token, user, ready, login, logout} = useAuth();
    const isAuthenticated = !!token && !!user;

    return !ready ?
        <Loading/> :
        <SnackbarProvider
            maxSnack={7}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        >
            <BrowserRouter>
                <AuthContext.Provider value={{token, user, isAuthenticated, login, logout}}>
                    <Routes>
                         {routes.map(route =>
                            <Route
                                key={route.path}
                                exact={route.exact}
                                path={route.path}
                                element={
                                    <Layout withMenu={route.with_menu} withSearch={route.with_search}>
                                        <Suspense fallback={<Loading/>}>
                                            <route.element/>
                                        </Suspense>
                                    </Layout>
                                }
                            />
                        )}
                        <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                        />
                    </Routes>
                </AuthContext.Provider>
            </BrowserRouter>
        </SnackbarProvider>
};

export default memo(App);