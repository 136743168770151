import {memo} from "react";
import {Dialog, DialogContent, AppBar, Toolbar, IconButton, Icon, Zoom} from "@mui/material";
import "./index.scss";


const CustomModal = ({
    isOpen,
    title,
    className,
    contentClassName,
    withCloseButton = true,
    onClose,
    children
}) => (
    <Dialog
        className={`custom-modal${className ? ' ' + className : ''}`}
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Zoom}
        scroll="body"
        fullWidth
    >
        {withCloseButton &&
            <IconButton className="custom-modal__close-button" onClick={onClose}>
                <Icon>close</Icon>
            </IconButton>
        }
        {title &&
            <AppBar position="static" className="custom-modal__top">
                <Toolbar className="custom-modal__title">
                    {title}
                </Toolbar>
            </AppBar>
        }
        <DialogContent className={`custom-modal__content${contentClassName ? ' ' + contentClassName : ''}`}>
            {children}
        </DialogContent>
    </Dialog>
);

export default memo(CustomModal);