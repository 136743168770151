import {memo, useState} from "react";
import CustomButton from "../CustomButton";
import {parseCookies} from "../../utils/cookies";
import "./index.scss";


const CookiesPolicy = () => {
    const cookies = parseCookies();
    const [isShown, setIsShown] = useState(!cookies.user_allowed);

    const acceptCookiesPolicy = () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);

        document.cookie = 'user_allowed=true; path=/; expires=' + date.toUTCString();
        setIsShown(false);
    };

    return isShown &&
        <div className="cookies-policy">
            <div className="cookies-policy__content content">
                <h4 className="cookies-policy__title">Уведомление на обработку файлов cookies</h4>
                <div className="cookies-policy__description">
                    <p>
                        Уведомление об обработке файлов cookies РКФ обрабатывает файлы cookies. Они помогают нам делать этот сайт удобнее для пользователей. Продолжая работу с сайтом&nbsp;
                        <a
                            href="https://info.rkf.online/"
                            title="https://info.rkf.online/"
                        >
                            https://info.rkf.online/
                        </a>
                        , вы соглашаетесь с обработкой файлов cookies вашего браузера в соответствии с&nbsp;
                        <a
                            href="https://info.rkf.online/Политика о порядке обработки персональных данных.pdf"
                            title="https://info.rkf.online/Политика о порядке обработки персональных данных.pdf"
                        >
                            Политикой о порядке обработки персональных данных
                        </a>
                        . Однако вы можете запретить обработку некоторых типов файлов cookies в настройках вашего браузера либо на странице «Уведомление об использовании файлов cookie.
                    </p>
                    <CustomButton
                        className="cookies-policy__button"
                        onClick={acceptCookiesPolicy}
                    >
                        Принять и закрыть
                    </CustomButton>
                </div>
            </div>
        </div>
};

export default memo(CookiesPolicy);