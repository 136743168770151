import {memo, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Icon, IconButton} from "@mui/material";
import CustomTooltip from "../../CustomTooltip";
import CustomSwitch from "../../CustomSwitch";
import "./index.scss";


const GlobalSearch = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const searchString = new URLSearchParams(location.search).get('s');
    const [searchValue, setSearchValue] = useState(searchString || '');
    const [isNeuroSearch, setIsNeuroSearch] = useState(false);

    const isMainPage = location.pathname === '/';

    useEffect(() => {
        setSearchValue(searchString || '');
    }, [searchString]);

    const onSearch = () => {
        navigate(`/${isNeuroSearch ? 'neuro-chat' : 'search'}?s=${searchValue.trim()}`);
    };

    return (
        <div className="global-search">
            {/* {isMainPage &&
                <div className="global-search__controls">
                    <CustomSwitch
                        firstButtonName="Поиск"
                        secondButtonName="Нейро"
                        checked={isNeuroSearch}
                        setChecked={setIsNeuroSearch}
                    />
                </div>
            } */}
            <div className="global-search__wrap">
                <input
                    type="text"
                    className="global-search__search-input"
                    placeholder="Поиск"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    onKeyDown={e => {
                        if(e.key === 'Enter' && searchValue.length > 2) onSearch();
                    }}
                />
                <CustomTooltip title="Поиск">
                    <span className="global-search__btn-wrap">
                        <IconButton
                            className="global-search__search-btn"
                            disabled={!searchValue || searchValue.length < 3}
                            onClick={onSearch}
                        >
                            <Icon>search</Icon>
                        </IconButton>
                    </span>
                </CustomTooltip>
            </div>
        </div>
    );
};

export default memo(GlobalSearch);